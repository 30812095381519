<template>
  <div>
    <header-desktop class="d-none d-lg-block"></header-desktop>
    <div class="d-block d-lg-none">
      <div class="headerMobile">
        <UIHeaderMobile :name="namePage" />
        <div class="ml-auto">
          <NavbarIconsRep />
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view
        class="layoutSubMain"
        @updateTitleMobile="updateTitleMobile"
        :namePage="namePage"
        :component="component"
        :key="$route.path"
      />
    </transition>
    <Footer class="d-none d-md-block"></Footer>
  </div>
</template>
<script>
import HeaderDesktop from '../components/HeaderDesktop';
import Footer from '../components/Footer';
import UIHeaderMobile from '@/components/UI/Header/UIHeaderMobile';
// import { getEntityInfo } from '../utils/functions';
import NavbarIconsRep from '../components/HeaderDesktop/NavbarIconsRep';

// import ModalRegister from "../components/Account/ModalRegister";
// import NavbarMobile from '../components/NavbarMobile';

export default {
  name: 'app',
  components: {
    HeaderDesktop,
    Footer,
    NavbarIconsRep,
    UIHeaderMobile
    // ModalRegister,
  },
  data() {
    return {
      gameId: undefined,
      isLoading: false,
      namePage: '',
      component: null
    };
  },
  watch: {},
  computed: {
    getListGame() {
      return this.$store.getters.getterGameUrl;
    }
  },
  created() {
    this.namePage = this.$route.meta.name || '';
    this.$store.commit('setModalLogin', false);
    this.$store.commit('setModalRegister', false);
  },
  methods: {
    updateTitleMobile(event) {
      this.namePage = event;
    }
  }
};
</script>
<style lang="scss">
// @import "../assets/sass/main";
</style>
